.text-editor-warpper {
    height: 100%;
    justify-content: space-between;
    display: flex;
    gap: 1rem;
}

.text-editor-container {
    width: 60%;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
}

.editor-chat-container {
    width: 35%;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    z-index: 99;
}

@media screen and (max-width: 768px) {

    /* .editor{
        width: 200%;
    } */
    .editor-chat-container,
    .text-editor-container {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .editor-chat-container {
        /* flex: 1;
      width: 50%; */
        position: fixed;
        top: 0;
        right: 0;
        width: 80%;
        height: 100%;
        background-color: #f0f0f0;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        z-index: 990;
    }
}

/*  */
.toggle-chat-btn {
    display: none;
}

.toggle-chat-btn {
    position: fixed;
    background-color: #0000FF;
    color: white;
    font-family: 'Epilogue';
    bottom: 20px;
    left: 20px;
    z-index: 991;
    border-radius: 10px;
    padding: 10px 16px;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    .toggle-chat-btn {
        display: block;

    }

    .toggle-chat-btn:hover {
        background-color: #3333ff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .toggle-chat-btn:active {
        transform: scale(0.95);
        transition: transform 0.1s ease-in-out;
    }
}