.privacy-section {
    display: flex;
    padding: 1rem;
    justify-content: center;
    background-color: #000000;
}

.privacy-section-warpper {
    max-width: 800px;
}


.white-color-text-h1-tag {
    font-family: 'Epilogue';
    color: white;
}

.white-color-text-h3-tag {
    font-family: 'Epilogue';
    color: white;
}

.white-color-text-h4-tag {
    font-family: 'Epilogue';
    color: white;
}

.white-color-text-p-tag {
    font-family: 'Epilogue';
    color: white;
}

.pp-table {
    font-family: 'Epilogue';
    color: white;
    font-size: 18px;
    text-justify: auto;
    text-align: left;
    line-height: 24px;
    font-weight: 300;
}

.privacy-section-warpper .white-color-text-h4-tag,
.white-color-text-p-tag {
    font-size: 18px;
    text-justify: auto;
    line-height: 24px;
    font-weight: 300;
}

@media (max-width: 767px) {

    .privacy-section-warpper .white-color-text-h4-tag,
    .white-color-text-p-tag, .pp-table {
        font-size: 14px;
        line-height: 16px;
        font-weight: 200;
    }
}