.search-bar-wrapper {
    position: relative;
    width: 400px;
}

@media (max-width: 767px) {
    .search-bar-wrapper {
        width: 100%;
    }
}

.search-result-container {
    width: calc(100% - 1rem - 12px);
    overflow: hidden;
}

@media (max-width: 767px) {
    .search-result-container {
        overflow: auto;
    }
}

.search-result-container:hover {
    overflow-y: auto;
}

.search-result-container::-webkit-scrollbar {
    width: 5px;
}

.search-result-container::-webkit-scrollbar-track {
    background: transparent;
}

.search-result-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 255, 0.5);
    border-radius: 10px;
}

.search-result-container::-webkit-scrollbar-button {
    display: none;
}

.search-result-container::-webkit-scrollbar-corner {
    background: transparent;
}


.text-docs-result-container .pdfs-result-container {
    margin-bottom: 10px;
}

.text-docs-result-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.text-docs-result-container ul li {
    margin-bottom: 0.5rem;
    border-bottom: 1px #ccc dashed;
}

.pdfs-result-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pdfs-result-container ul li {
    margin-bottom: 0.5rem;
    border-bottom: 1px #ccc dashed;
}

.result-link {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    list-style-type: none;
    width: calc(100% -10px);
    text-decoration: none;
    transition: background-color 0.3s;
    font-family: 'Epilogue';
    color: #000;
    font-size: 16px;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
}

.result-link-text {
    width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.result-link-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.result-link:hover {
    background-color: #D2D7F4;
}