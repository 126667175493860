.main {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}

.pdf-warpper {
    width: 100%;
    justify-content: center;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

}

canvas.react-pdf__Page__canvas {
    margin-bottom: 1rem;
}

.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pdf-controls {
    display: inline-flex;
    z-index: 990;
    position: sticky;
    top: 0;
    right: 0;
    margin: 0 1rem;
    list-style: none;
    padding: 0;
}

.pdf-controls li {
    margin: 10px;
}

.pdf-controls li button {
    background-color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}