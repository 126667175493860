.key-takeaways-container-title {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 1rem; */
    /* position: sticky; */
    /* top: -1rem; */
    z-index: 9;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    background-color: #F0F0F0;
}

.grid-item {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    position: relative;
}

.grid-item p {
    font-family: Epilogue;
}

.ellipsis-menu {
    background-color: transparent;
    border: none;
    z-index: 1;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ellipsis-menu:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.menu-dropdown {
    padding: 20px;
    position: absolute;
    right: 10px;
    top: 40px;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
}

.menu-dropdown li {
    padding: 12px 16px;
    text-decoration: none;
    color: black;
    cursor: pointer;
    display: block;
    padding: 0.5rem;
    font-family: Epilogue;
}

.menu-dropdown li span {
    margin-left: 10px;
}

.menu-dropdown li:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.key-points-container {
    margin-top: 2rem;
}

.ant-select-selection {
    background-color: green;
}


.grid-container {
    overflow: hidden;
}

.grid-container:hover {
    overflow-y: auto;
}

.grid-container::-webkit-scrollbar {
    width: 5px;
}

.grid-container::-webkit-scrollbar-track {
    background: transparent;
}

.grid-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 255, 0.5);
    border-radius: 10px;
}

.grid-container::-webkit-scrollbar-button {
    display: none;
}

.grid-container::-webkit-scrollbar-corner {
    background: transparent;
}

.menu-icon {
    font-size: 18px;
    color: #000;
}

.unpin-icon {
    position: relative;
    transform: rotate(-45deg);
}

.pin-icon {
    transform: rotate(-45deg);
}

.unpin-icon::after {
    content: "";
    width: 24px;
    border: 0.5px solid #000;
    background-color: #000;
    position: absolute;
    font-size: 18px;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%) rotate(0deg);
}