.sign-up-link {
    width: 140px;
    height: 46px;
    border-radius: 23px;
    background-color: #0000FF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-up-link a {
    text-transform: uppercase;
    font-family: 'Epilogue';
    text-decoration: none;
    padding: 0;
    color: #fff !important;
}

.sign-up-link:hover {
    background-color: #0000cd;
}

/* Hero-section */
.hero-section {
    background-image: url('../../../public/backgroundMask.webp');
    background-size: cover;
    height: calc(100vh - 100px);
    display: flex;
    background-color: #000;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero-section-tag-line {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero-section-tag-line h1 {
    font-size: 142px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-family: 'Epilogue';
    letter-spacing: 4px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .hero-section-tag-line h1 {
        font-size: 62px;
    }
}

.hero-section-tag-line h1 span {
    background: -webkit-linear-gradient(#525252, #0000FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-section-tag-line p {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Epilogue';
    color: #0000FF;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
}

/* Education Re-Imagined Section */
.education-re-imagined-section {
    display: flex;
    padding: 50px 0;
    background-color: #000;
}

.education-re-imagined-section-warpper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
}

@media (max-width: 767px) {
    .education-re-imagined-section-warpper {
        flex-direction: column;
    }
}

.left-sec-comp,
.right-sec-comp {
    flex-basis: 50%;
    padding: 1rem;
}

@media (max-width: 767px) {

    .left-sec-comp,
    .right-sec-comp {
        padding: 0;
    }
}

.left-sec-comp>div,
.right-sec-comp>div {
    margin-bottom: 2rem;
}

.left-sec-comp-text p {
    font-size: 18px;
    color: #fff;
    text-align: justify;
    font-family: 'Epilogue';
    line-height: 24px;
    font-weight: 300;
}

@media (max-width: 767px) {
    .left-sec-comp-text p {
        font-size: 15px;
        line-height: 26px;
        font-weight: 300;
    }
}

.left-sec-comp-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.left-sec-comp-links a {
    padding: 10px 60px;
    border-radius: 25px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Epilogue';
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
}

@media (max-width: 767px) {
    .left-sec-comp-links {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .left-sec-comp-links a {
        width: 100%;
        padding: 10px 0;
    }
}

.left-sec-comp-links .link-login {
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #0000FF;
    color: #fff;
}

.left-sec-comp-links .link-pricing {
    background-color: #0000FF;
    color: #fff;
    border-style: solid;
    border-width: 2px;
    border-color: #0000FF;
}

.right-sec-comp {
    background-color: #0000FF;
}

.right-sec-comp div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.right-sec-comp-title h2 {
    /* font-size: 48px; */
    color: #fff;
    font-family: 'Epilogue';
    font-weight: 700;
}

.right-sec-comp-paragraph p {
    font-size: 18px;
    color: #fff;
    text-align: justify;
    font-family: 'Epilogue';
    line-height: 24px;
    font-weight: 300;
}

@media (max-width: 767px) {
    .right-sec-comp-paragraph p {
        padding: 0 1rem;
        font-size: 15px;
        line-height: 26px;
        font-weight: 300;
    }
}


/* who-we-are-section */
.who-we-are-section {
    background-image: url('../../../public/backgroundMask.webp');
    background-size: cover;
    background-position: center;
    background-color: #000;
    display: flex;
}

.who-we-are-section-warpper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.who-we-are-section-warpper div {
    margin-bottom: 20px;
}

.who-we-are-section-warpper h2 {
    max-width: 800px;
    font-size: 64px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-family: 'Epilogue';
    letter-spacing: 2px;
}

@media (max-width: 767px) {
    .who-we-are-section-warpper h2 {
        font-size: 36px;
    }
}

.who-we-are-section-warpper p {
    max-width: 800px;
    font-size: 18px;
    color: #fff;
    text-align: justify;
    font-family: 'Epilogue';
    line-height: 24px;
    font-weight: 300;
}

@media (max-width: 767px) {
    .who-we-are-section-warpper p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 200;
    }
}

.who-we-are-section-warpper a {
    padding: 10px 60px;
    border-radius: 25px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Epilogue';
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #0000FF;
    color: #fff;
}

/* Features Section */
.features-section {
    background-color: #000;
    padding: 50px 0;
    display: flex;
}

.features-section-wrapper {
    width: 100%;
    padding: 1rem;
}

@media (max-width: 767px) {
    .features-section-wrapper {
        padding: 0;
    }
}

.features-sec-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .features-sec-header {
        margin: 0 1rem;
    }
}

.features-sec-header h2 {
    font-size: 64px;
    color: #fff;
    font-weight: 700;
    font-family: 'Epilogue';
    letter-spacing: 2px;
}

@media (max-width: 767px) {
    .features-sec-header h2 {
        font-size: 36px;
    }
}

.features-sec-header p {
    width: 50%;
    font-size: 16px;
    font-weight: 400;
    color: #0000FF;
    text-align: justify;
    font-family: 'Epilogue';
    line-height: 20px;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .features-sec-header p {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
    }
}

.features-card-container {
    position: relative;
    overflow: hidden;
}

.features-slide-container {
    margin: 20px 0;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.features-slide-container::-webkit-scrollbar {
    width: 0;
}

.features-slide-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.feature-card {
    flex: 0 0 calc(80% - 20px);
    margin-right: 20px;
    padding: 20px;
    box-sizing: border-box;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .feature-card {
        display: flex;
        flex-direction: column;
        min-width: 100%;
    }
}

.feature-card-image-container {
    flex: 0 0 60%;
    height: 100%;
    position: relative;
}

@media (max-width: 767px) {
    .feature-card-image-container {
        flex: none;
        height: auto;
    }
}

.feature-card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.feature-card-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    mask-image: radial-gradient(ellipse closest-side, transparent 50%, #000 100%);
    transition: all 0.3s ease;
}

.feature-card-image-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 20px 10px rgba(0, 0, 0, 0.3);
    mask-image: radial-gradient(ellipse closest-side, transparent 50%, #000 100%);
    transition: all 0.3s ease;
}

.feature-card-desc-container {
    flex: 0 0 40%;
    background-color: #0000FF;
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .feature-card-desc-container {
        flex: 1;
        width: calc(100% - 40px);
        height: 300px;
    }
}

@media (max-width: 767px) {
    .feature-card-image-container .feature-card-desc-container {
        flex: 1;
        width: 100%;
        height: 300px;
    }
}

.feature-card-desc-container h3 {
    text-align: center;
    line-height: 30px;
    font-size: 28px;
    color: #fff;
    text-align: left;
    font-weight: 600;
    font-family: 'Epilogue';
}

@media (max-width: 767px) {
    .feature-card-desc-container h3 {
        font-size: 22px;
        line-height: 24px;
    }
}

.features-slide-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
}

.control-button {
    width: 40px;
    height: 4px;
    border-radius: 5px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, width 0.3s ease;
    /* Add width transition */
}

.control-button.active {
    background-color: #0000FF;
    width: 80px;
}

.control-button:hover {
    background-color: #0000cd;
}

/* the-forward-section */
.the-forward-section {
    background-image: url('../../../public/backgroundMask.webp');
    background-size: cover;
    background-position: center;
    background-color: #000;
    display: flex;
}

.the-forward-section-warpper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 2rem;
}

.the-forward-section-warpper div {
    margin-bottom: 20px;
}

.the-forward-section-warpper h2 {
    max-width: 800px;
    font-size: 64px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-family: 'Epilogue';
    letter-spacing: 2px;
}

@media (max-width: 767px) {
    .the-forward-section-warpper h2 {
        font-size: 36px;
    }
}

.the-forward-section-warpper p {
    max-width: 800px;
    font-size: 18px;
    color: #fff;
    text-align: justify;
    font-family: 'Epilogue';
    line-height: 24px;
    font-weight: 300;
}

@media (max-width: 767px) {
    .the-forward-section-warpper p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 200;
    }
}

.the-forward-section-warpper a {
    padding: 10px 60px;
    border-radius: 25px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Epilogue';
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #0000FF;
    color: #fff;
}