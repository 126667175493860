.footer {
  background-color: #000;
  padding: 1rem
}

.column {
  padding: 1rem 0;
}

.column h2 {
  font-size: 64px;
  color: #fff;
  font-weight: 700;
  font-family: 'Epilogue';
  letter-spacing: 2px;
}

@media (max-width: 767px) {
  .column h2 {
    font-size: 36px;
  }
}

.footer-form {
  width: 50%;
}

@media (max-width: 767px) {
  .footer-form {
    width: 100%;
  }
}

.footer-form form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem
}

.footer-form form label {
  font-family: 'Epilogue';
  color: #fff;
  font-size: 16px;
}

.footer-form form input {
  border: solid;
  color: #808080;
  border-color: #808080;
  border-width: 1px;
  background-color: transparent;
  font-family: 'Epilogue';
  padding: 10px 20px;
}

.footer-form form textarea {
  min-height: 75px;
  border: solid;
  color: #808080;
  border-color: #808080;
  border-width: 1px;
  background-color: transparent;
  font-family: 'Epilogue';
  padding: 10px 20px;
}

.footer-form form textarea::-webkit-scrollbar {
  width: 0;
}

.footer-form form textarea {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.footer-form form button {
  padding: 10px 60px;
  border-radius: 25px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Epilogue';
  font-size: 12px;
  font-weight: 500;
  background-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-color: #0000FF;
  color: #fff;
  text-align: center;
  width: 200px;
}

.footer hr {
  border-color: #808080;
  border-width: 1px;
  margin: 30px 0;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .footer-bottom {
    flex-direction: column-reverse;
  }
}

.footer .copyright {
  padding: 0;
  font-family: 'Epilogue';
  color: #fff;
}

.footer .social-links {
  padding: 0;
  display: inline-flex;
  list-style: none;
  gap: 2rem;
}

.footer .social-links li a {
  font-family: 'Epilogue';
  text-decoration: none;
  color: #808080;
  cursor: pointer;
}

.footer .privacy-terms-links {
  padding: 0;
  display: inline-flex;
  list-style: none;
  gap: 2rem;
}

.footer .privacy-terms-links li a {
  font-family: 'Epilogue';
  text-decoration: none;
  color: #808080;
  cursor: pointer;
}

.notification {
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.notification.success {
  background-color: green;
}

.notification.error {
  background-color: red;
}


.footer-form form textarea:focus {
  outline: none;
  color: blue;
  border: 1px solid black;
  text-decoration: underline;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}
