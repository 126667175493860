.dashboard {
  height: 100%;
}

.dashboard-header {
  display: 'flex';
  align-items: 'center';
  width: 80%;
  background-color: #D2D7F4;
  border-radius: 30px;
  padding: 40px 0;
  text-align: center;
  margin: auto;
  margin-top: 4rem;
}

@media (max-width: 767px) {
  .dashboard-header {
    width: 100%;
  }
}

.dashboard-heading {
  font-family: 'Epilogue';
  color: #000;
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 20px;
}

.dashboard-heading br {
  display: block;
  margin-top: 8px;
}

@media (max-width: 767px) {
  .dashboard-heading {
    font-size: 36px;
  }
}

.pdfs-docs-container {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 2rem;
}

@media (max-width: 767px) {
  .pdfs-docs-container {
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.documents-column {
  overflow: hidden;
}

.document-container {
  max-height: calc(100% - 6.5rem);
  overflow: hidden;
}

.documents-column,
.pdfs-column {
  width: 50%;
}

.pdf-list-warpper {
  overflow: hidden;
}

@media (max-width: 767px) {

  .documents-column,
  .pdfs-column {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .document-container {
    overflow: auto;
  }
}

.document-container:hover {
  overflow-y: auto;
}

.document-container::-webkit-scrollbar {
  width: 5px;
}

.document-container::-webkit-scrollbar-track {
  background: transparent;
}

.document-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 255, 0.5);
  border-radius: 10px;
}

.document-container::-webkit-scrollbar-button {
  display: none;
}

.document-container::-webkit-scrollbar-corner {
  background: transparent;
}

.document-card {
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.document-card-meta {
  padding: 24px 16px;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.document-card-right {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.doc-name {
  font-family: 'Epilogue';
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-text-outlined {
  font-size: 60px;
  color: #0000FF;
  background: rgba(0, 0, 255, 0.1);
  border-radius: 10px;
  padding: 1rem;
}

@media (max-width: 767px) {
  .file-text-outlined {
    font-size: 40px;
  }
}

.file-name {
  font-family: 'Epilogue';
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.document-button {
  color: #1890ff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #1890ff;
  cursor: pointer;
  font-family: 'Epilogue', sans-serif;
}

.ellipsis-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ellipsis-button:hover {
  background-color: rgb(0, 0, 0, 0.1);
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* .delete-button {
  font-family: 'Epilogue';
  display: inline-block;
  background-color: #d9534f;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease;
  text-align: center;
}

.delete-button:hover {
  background-color: #c74945;
} */