.cl-internal-phfxlr {
    background-color: transparent !important;
    border-radius: 10px !important;
    border: 1px #ccc solid !important;
    padding: 0 !important;
    margin: 0 !important;
    font-family: 'Epilogue' !important;
    min-width: 100% !important;
}

.cl-internal-rx251b {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;


}

.cl-internal-b3fm6y {
    display: none;
}

.cl-userButtonPopoverActionButton__manageAccount {
    display: none !important;
}

.cl-internal-jqrj10 {
    background-color: #d9534f !important;
}