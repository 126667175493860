#toolbar {
  position: sticky;
  width: 100%;
  z-index: 990;
  background-color: #f5f5f5;
  border: none;
  padding: 1rem
}

.ql-container {
  border: none;
  border-width: 0px;
  border-radius: 10px;
  background-color: #f5f5f5;
  min-height: 842px;
}